import React from 'react'
import classnames from 'classnames'

import Header from '../Header'
import { HeaderVariant } from '../../types'
import styles from './styles.module.scss'
import Ribbon from '../Ribbon'

interface Props extends React.PropsWithChildren {
  headerVariant?: HeaderVariant
  fixedSidebar?: boolean
}

const Layout: React.FC<Props> = ({ children, fixedSidebar, headerVariant }) => {
  const innerContentClass = classnames({
    [styles.innerContentFixedSidebar]: fixedSidebar,
  })

  return (
    <>
      <Ribbon.Wrapper>
        <Ribbon.Item className={styles.ribbonDetail}>
          If you need help right away, please call us.
        </Ribbon.Item>

        <Ribbon.Item>
          Edgewater <strong>(410) 956-4488</strong>
        </Ribbon.Item>

        <Ribbon.Item>
          National Harbor <strong>(301) 567-9424</strong>
        </Ribbon.Item>
      </Ribbon.Wrapper>

      <Header variant={headerVariant} />

      <div className={innerContentClass}>{children}</div>
    </>
  )
}

export default Layout
